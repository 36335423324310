import { breakpoints, colors } from "style";
import styled from "styled-components";

export const LoginContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  position: relative;
  .login-container {
    flex:1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    text-align: center;
    .ant-select-selector {
      background: white;
      height: 40px;
      border-width: 0;
      font-size: 14px;
      border-radius: .25rem;
      padding: .375rem .75rem;
    }
    .merchant-logo-wrap {
      // margin-top: 1rem;
      // margin-bottom: .5rem;
      width: 100%;
      text-align: center;
      div {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        margin: auto;
        overflow: hidden;
        margin-bottom: 6px;
        img {
          width: 100%;
        }
      }
      h3 {
        text-transform: capitalize;
        color: black;
        position: relative;
        font-size: 16px;
        font-weight: 500;
      }
    }
    h3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: #3B7779;
    }
    h5 {
      font-weight: 400;
      font-size: 12px;
      color: #575757;
    }
    .social {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 6px;
      .btn {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 58px;
        height: 58px;
        margin: 0px 8px;
        background: #FFFFFF;
        box-shadow: 0px 9px 14px rgba(217, 217, 217, 0.25);
        border-radius: 50%;
        :hover {
          opacity: .8;
        }
      }
      .btn-facebook {
        padding: 0;
        img {
          width: 44px;
        }
      }
      .btn-twitter {
        padding: 0;
      }
      .btn-google {
        padding: 0;
        img {
          width: 40px;
        }
      }
      .btn-whatsapp {
        padding: 0;
        img {
          width: 42px;
        }
      }
      .btn-email {
        padding: 0;
        img {
          width: 56px;
        }
      }
    } 
    .email-login-label {
      font-weight: 500;
      font-size: 14px;
      color: #DBDBDB;
    }
    form {
      width: 100%;
      .form-control {
        background: white;
        height: 40px;
        border-width: 0;
        font-size: 14px;
        &::-webkit-input-placeholder {
          font-size: 14px !important;
        }
        &:focus, &:active {
          outline:0px !important;
          -webkit-appearance:none;
          box-shadow: none !important;
        }
      }
      .btn {
        margin-top: 18px !important;
      }
    }
    // span {
    //   margin-top: 12px;
    //   align-self: center;
    //   color: ${colors.red};
    //   font-size: 14px;
    //   a {
    //     color: ${colors.red};
    //     text-decoration: underline;
    //     font-weight: bold;
    //   }
    // }
    .login-footer p {
      font-size: 14px;
      color: var(--mr-color-4);
    }
  }
  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
