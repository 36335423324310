import React from "react";
import { Modal } from "antd";
import {
    FaTimesCircle
  } from "react-icons/fa";
import { ModalContainer } from "./style"

const ModalConfirmation = (props) => {

  const confirmModal = () => {
    props.toggleModal()
    props.modalCallback(true)
  }

  return ( 
    <>
    <Modal
        title={false}
        visible={props.modalVisible}
        onCancel={() => {
          props.toggleModal()
        }}
        centered
        closable={true}
        footer={false}
        maskClosable={false}
        className={props.className}
        // getContainer={() => document.getElementById("stamp-container")}
        width='80%'
        closeIcon={<FaTimesCircle className='close-icon'/>}
      >
        <ModalContainer>
          
          <div className="header w-100">
              {props.header}
          </div>

          <div className="body w-100">
              {props.body}
          </div>
          
          {
            props.noFooter? null : (
              <div className='footer w-100'>
                <button onClick={() => props.toggleModal()} className='btn btnConfirm mr-1'>Cancel</button>
                <button onClick={() => confirmModal()} className='btn btnEmpty ml-1'>Confirm</button>
              </div>
            )
          }
        </ModalContainer>
      </Modal>
    </>
  );
}
 
export default ModalConfirmation;