import { useEffect, useState, useContext } from 'react';
import { Formik, useField, useFormikContext } from "formik";
import PinInput from 'react-pin-input';
import randomstring from  'randomstring';
import * as Yup from "yup";
import { Col, Form, InputGroup } from "react-bootstrap";
import { Select, DatePicker, Modal } from 'antd';
import { DataContext } from "contexts/DataContextContainer";
import { ProfileContainer } from "./style";
import { connect } from 'react-redux'
import ModalConfirmation from 'components/Extra/ModalConfirmation';
import { updateDetails } from 'redux/actions/roleActions';
import CountryList from 'country-list-with-dial-code-and-flag'
import { UserLogo, merchantShop } from "assets/images";
import {
  FaCamera
} from "react-icons/fa";
import Validation from 'data/validation.js'
import moment from 'moment';
import SocialButton from "../Login/SocialButton";
import { useGoogleLogin } from '@react-oauth/google';

import Cropper from "react-cropper";

import 'services/i18'
import { useTranslation as translation } from 'react-i18next';

import axios from 'axios';
import client from "feathers.js"

import { FacebookIconWhite, GoogleIconWhite } from "assets/images"

const EmailSchema = Yup.object().shape({
  email: Yup.string().email().required("Required!"),
});

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Please Enter your password'),
    // .matches(
    //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    // ),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required("Required!"),
});

const ContactSchema = Yup.object().shape({
  contact_phone: Yup.string().required("Required!").matches(Validation.PHONE_REG_WITHOUT_COUNTRY_CODE, 'Phone number is not valid')
});

const PersonalDetailsSchema = Yup.object().shape({
  firstName: Yup.string().required("Required!"),
  lastName: Yup.string().required("Required!"),
});

const { Option } = Select;

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={(val) => {
        setFieldValue(field.name, val);
      }}
    />
  );
};

const isUrl = string => {
  try { return Boolean(new URL(string)); }
  catch(e){ return false; }
}

const Profile = (props) => {
  const { setLoading, showToast } = useContext(DataContext)
  const [ phonePrefix, setPhonePrefix ] = useState('+65');
  const [ selectedLanguage, setSelectedLanguage ] = useState(props.userInfo.selectedLanguage);
  const [ visitList, setVisitList ] = useState([]);
  const [ savedMerchants, setSavedMerchants ] = useState([]);
  const [ tmpValues, setTmpValues ] = useState({});
  const [ passwordAction, setPasswordAction ] = useState('new');
  const [modalVisible, setModalVisible] = useState(false)
  const [modalVisibleEmail, setModalVisibleEmail] = useState(false)

  const {t} = translation()

  const [modalVisibleCropper, setModalVisibleCropper] = useState(false)
  const [logoImage, setLogoImage] = useState(null)
  // const [croppedLogoUrl, setCroppedLogoUrl] = useState(null)
  const [cropper, setCropper] = useState(null);
  const [vcode, setVcode] = useState('');

  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }

  const toggleModalEmail = () => {
    setModalVisibleEmail(!modalVisibleEmail)
  }

  // const modalCallbackEmail = () => {
  // }

  // const modalCallback = () => {
  // }
  useEffect(() => {
    client.authenticate()
    .then(() => {
      localStorage.setItem('selectedLanguage', props.userInfo.selectedLanguage)
    })
  })

  useEffect(() => {
    client.authenticate()
    .then(() => {
      return client.service('users').find({
        query: {
          email: props.userInfo.email,
          role: 'user',
          $select: ['merchantId', 'merchantName']
        }
      })
    })
    .then((res) => {
      setVisitList(res.data)
    })
    .catch((err)=>{
      console.log(err)
    })
  }, [props.userInfo.email])

  useEffect(() => {
    client.authenticate()
    .then(() => {
      return client.service('save-merchants').find({
        query: {
          userId: props.userInfo._id,
        }
      })
    })
    .then((res) => {
      setSavedMerchants(res.data)
    })
    .catch((err)=>{
      console.log(err)
    })
  }, [props.userInfo._id])

  useEffect(() => {
    if(props.userInfo.phonePrefix){
      setPhonePrefix(props.userInfo.phonePrefix)
    }
  }, [props.userInfo.phonePrefix])

  const onUpdateContat = (value, { resetForm }) => {
    value.phonePrefix = phonePrefix
    updateInfo(value)
  }
  
  const handleVcodeDone = (value, index) => {
    
    if(value === vcode){
      setLoading(true)
      client.authenticate()
      .then(() => {
        return client.service('users').find({
          query: {
            _id: props.userInfo._id,
            checkIfPasswordExist: true,
            $limit: 1
          }
        })
      })
      .then((res) => {
        if(res.data.length > 0){
          if(res.data[0].hasPassword){
            // verify password
            // setPasswordAction('verify')
            // toggleModal()
            
            updateInfo(tmpValues)
            toggleModalEmail()

          }else{
            // set new password
            setPasswordAction('new')
            toggleModal()
          }
        }
        setLoading(false)
      })
      .catch((err)=>{
        if(err.name === "NotAuthenticated"){
          
        }else{
          showToast('error', err.message)
        }
        setLoading(false)
      })
    }else{
      showToast('error', 'Invalid Verification code!')
    }
    
  }

  const onUpdateEmail = (value, { resetForm }) => {
    if(value.email){
      value.action = 'email'

      setLoading(true)
      let createVcode = randomstring.generate({length: 4, charset: 'numeric'})
      setVcode(createVcode)
      setTmpValues(value)

      client.authenticate()
      .then((auth) => {
        return axios.post(`${client.io.io.uri}sendVcodeUser`, {
          _id: props.userInfo._id,
          email: value.email,
          vcode: createVcode,
        },
        {
          headers: {
            'Authorization': auth.accessToken
          }
        }
        )
      })
      .then((res) => {
        setModalVisibleEmail(true)
        // toggleModalEmail()
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        showToast('error', err.message)
      })
    }
  }

  const updateNewPassword = async(values, { resetForm }) => {
    values.action = tmpValues.action
    values.email = tmpValues.email
    values.passwordAction = passwordAction // new
    updateInfo(values)
    setModalVisible(false)
    resetForm()
  }

  const updateLanguage = async(values, { resetForm }) => {
    values.selectedLanguage = selectedLanguage
    updateInfo(values)
  }

  const verifyPassword = async(values, { resetForm }) => {
    values.action = tmpValues.action
    values.email = tmpValues.email
    values.passwordAction = passwordAction // verify
    updateInfo(values)
    setModalVisible(false)
    resetForm()
  }

  const onUpdatePassword = (value, { resetForm }) => {
    updateInfo(value, resetForm)
  }

  const onUpdatePersonalDetails = (value, { resetForm }) => {
    updateInfo(value)
  }

  // const onUpdateSocial = (value, { resetForm }) => {
  //   console.log(value)
  //   resetForm()
  // }

  const updateInfo = (values, resetForm) => {
    setLoading(true)

    client.authenticate()
    .then(() => {

      return client.service('users').patch(props.userInfo._id, values)
    })
    .then((res) => {
      if(resetForm){
        resetForm()
      }
      props.updateDetails(res)
      setModalVisibleEmail(false)
      showToast('success', 'Update Successfully')
      setLoading(false)
      setTimeout(() => window.location.reload(), 500)
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        
      }else{
        showToast('error', err.message)
      }
      setLoading(false)
    })
  }

  const uploadUserLogo = async(fileData) => {
    return axios.post(`${client.io.io.uri}uploadPublicImgAndDelete`, {
      _id: props.userInfo._id,
      fileData: fileData,
      deleteFileName: props.userInfo.profilePicURL,
      toFolder: 'userLogos',
      prefix: 'user'
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const handleImgChange = async(e) => {

    e.preventDefault();

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setLogoImage(reader.result)
      setModalVisibleCropper(true)
    };
    reader.readAsDataURL(files[0]);
    
    e.target.value = null
    // }else{
    //   window.alert("File size Limit: 2000000 kb!")
    //   return false
    // }
  }

  const getCropData = async() => {
    if (typeof cropper !== "undefined") {
      // setCroppedLogoUrl(cropper.getCroppedCanvas().toDataURL());
    
      setLoading(true)
    
      var newFileName = await uploadUserLogo(cropper.getCroppedCanvas().toDataURL())

      client.authenticate()
      .then(() => {
        return client.service('users').patch(props.userInfo._id, {
          profilePicURL: newFileName
        })
      })
      .then((res) => {
        props.updateDetails(res)
        // showToast('success', 'Update Successfully')
        setLoading(false)
      })
      .catch((err)=>{
        if(err.name === "NotAuthenticated"){
        
        }else{
          showToast('error', err.message)
        }
        setLoading(false)
      })

    }
  };

  const responseFacebook = async(response) => {
    const { data } = await axios.get('https://graph.facebook.com/me', {
      headers: {
        authorization: `Bearer ${response._token.accessToken}`
      },
      params: {
        // There are 
        fields: 'id,name,email,picture,birthday,about'
      }
    });

    setLoading(true)

    client.authenticate()
    .then(() => {
      return client.service('users').patch(props.userInfo._id, {
        facebookId: data.id,
        facebookEmail: data.email,
        action: "link"
      })
    })
    .then((res) => {
      props.updateDetails(res)
      showToast('success', 'Account Linked Successfully')
      setLoading(false)
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        
      }else{
        showToast('error', err.message)
      }
      setLoading(false)
    })
  }

  const loginGoogle = useGoogleLogin({
    onSuccess: tokenResponse => responseGoogle(tokenResponse),
  });
  
  const responseGoogle = async(response) => {
    setLoading(false)

    let getData = await axios.get('https://www.googleapis.com/oauth2/v1/userinfo', {
      headers: {
        authorization: `Bearer ${response.access_token}`
      },
    });

    let data = getData.data
    data.googleId = data.id
    data.firstName = data.family_name
    data.lastName = data.given_name
    data.profilePicURL = data.picture

    setLoading(true)

    client.authenticate()
    .then(() => {
      return client.service('users').patch(props.userInfo._id, {
        googleId: data.id,
        googleEmail: data.email,
        action: "link"
      })
    })
    .then((res) => {
      props.updateDetails(res)
      showToast('success', 'Account Linked Successfully')
      setLoading(false)
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        
      }else{
        showToast('error', err.message)
      }
      setLoading(false)
    })
  }

  const RenderFacebookComponent = () => {
    const enableComponent = () => {
      if(document.getElementById('btn-facebook')){
        document.getElementById('btn-facebook').remove()
      }
    }

    return (
      <>
        <SocialButton
          provider="facebook"
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          className="btn-facebook"
          onLoginSuccess={responseFacebook}
          onLoginFailure={console.log}
          getInstance={() => enableComponent()}
        >
          <img src={FacebookIconWhite} alt="" /> {t('Profile LinkWithFacebook')}
        </SocialButton>
        <button className="btn-facebook" id="btn-facebook" >
          <img src={FacebookIconWhite} alt="" /> {t('Profile LinkWithFacebook')}
        </button>
      </>
    )
  }

  const RenderGoogleComponent = () => {
    const enableComponent = () => {
      if(document.getElementById('btn-google')){
        document.getElementById('btn-google').remove()
      }
    }

    return (
      <>
        <SocialButton
          provider="google"
          appId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          className="btn-google"
          onLoginSuccess={responseGoogle}
          onLoginFailure={console.log}
          getInstance={() => enableComponent()}
        >
          <img src={GoogleIconWhite} alt="" /> {t('Profile LinkWithGoogle')}
        </SocialButton>
        <button id="btn-google" className="btn-google" disabled>
          <img src={GoogleIconWhite} alt="" /> {t('Profile LinkWithGoogle')}
        </button>
      </>
    )
  }
  
  const RenderSocialLogin = () => {
    return (
      <div className='social'>
        {
          props.userInfo.facebookId?(
            <button className="btn-facebook" id="btn-facebook" >
              <img src={FacebookIconWhite} alt="" /> {t('Profile LinkedStatus')}
          </button>
          ): (
            <RenderFacebookComponent />
          )
        }
        {
          props.userInfo.googleId?(
            <button id="btn-google" className="btn-google" disabled>
              <img src={GoogleIconWhite} alt="" /> {t('Profile LinkedStatus')}
            </button>
          ): (
            <RenderGoogleComponent />
          )
        }
        {/* <button className="btn btn-twitter"><i className="fab fa-twitter"></i> Twitter</button> */}
      </div>
    )
  }

  return (
    <ProfileContainer onWheel={ event => {
      if (event.nativeEvent.wheelDelta > 0) {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('down')
          document.getElementById('tab-wrap').classList.add('up')
        }
      } else {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('up')
          document.getElementById('tab-wrap').classList.add('down')
        }
      }
    }}>
      <div className='profile-container' id="stamp-container">
        <div className='picture'>
          <img 
            src={
              props.userInfo.profilePicURL? (
                isUrl(props.userInfo.profilePicURL)? props.userInfo.profilePicURL: (
                  client.io.io.uri + props.userInfo.profilePicURL
                )
              ): UserLogo
            } 
            alt="" />
          <label htmlFor="img-logo" className='add-picture'>
            <FaCamera/>
          </label>
          <input 
            id="img-logo" 
            type="file" 
            onChange={e => handleImgChange(e)} 
            style={{display: 'none', cursor: 'pointer'}} 
          />
        </div>

        <div className='info'>
          <div className='info-section personal-info'>
             <Formik
              enableReinitialize
              validationSchema={EmailSchema}
              onSubmit={onUpdateEmail}
              initialValues={{
                email: props.userInfo.email? props.userInfo.email: "",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
              }) => (
                <Form onSubmit={handleSubmit} className="w-100">
                  <div className='section-row-1'>
                    <span className='narrator'>{t('Profile Email')}</span>
                    <Form.Control
                      type="text"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                      placeholder={t('Profile Email Placeholder')}
                    />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                  </div>
                  <div className='w-100 text-center'>
                    <button 
                      type="submit" 
                      className='btn-update'
                      disabled={props.userInfo.email === values.email? true: false}
                    >{t('Profile UpdateButton')}</button>
                  </div>
                </Form>
              )}
            </Formik>
            <hr className='w-100'/>
            <Formik
              enableReinitialize
              validationSchema={PersonalDetailsSchema}
              onSubmit={onUpdatePersonalDetails}
              initialValues={{
                birthday: props.userInfo.birthday? moment(new Date(props.userInfo.birthday),'DD/MM/YYYY'): "",
                gender: props.userInfo.gender? props.userInfo.gender: "",
                firstName: props.userInfo.firstName? props.userInfo.firstName: "",
                lastName: props.userInfo.lastName? props.userInfo.lastName: "",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                errors,
              }) => (
                <Form onSubmit={handleSubmit} className="w-100">
                  <div className='section-row-1'>
                    <span className='narrator'>{t('Profile PersonalDetails')}</span>
                    <Form.Row className="mt-2">
                      <Col xs={6}>
                          <Form.Control
                            type="text"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            isInvalid={!!errors.firstName}
                            placeholder={t('Profile FirstName Placeholder')}
                          />
                        <Form.Control.Feedback type="invalid" className='input-group-feedback'>{errors.firstName}</Form.Control.Feedback>
                      </Col>
                      <Col xs={6}>
                          <Form.Control
                            type="text"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            isInvalid={!!errors.lastName}
                            placeholder={t('Profile LastName Placeholder')}
                          />
                        <Form.Control.Feedback type="invalid" className='input-group-feedback'>{errors.lastName}</Form.Control.Feedback>
                      </Col>
                    </Form.Row>
                  </div>
                  <div className='section-row-2'>
                    <Select 
                      name={t('Profile Gender')}
                      value={values.gender}
                      onChange={(e) => setFieldValue('gender', e)}
                    >
                      <Option value="">{t('Profile PreferNotToSay')}</Option>
                      <Option value="male">{t('Profile GenderMale')}</Option>
                      <Option value="female">{t('Profile GenderFemale')}</Option>
                    </Select>
                    <DatePickerField 
                      value={values.birthday}
                      name="birthday" 
                      placeholder={t('Profile Birthday Placeholder')}
                    />
                  </div>
                  <div className='w-100 text-center'>
                    <button type="submit" className='btn-update'>{t('Profile UpdateButton')}</button>
                  </div>
                </Form>
              )}
            </Formik>
            <hr className='w-100'/>
            
            <Formik
              enableReinitialize
              validationSchema={ContactSchema}
              onSubmit={onUpdateContat}
              initialValues={{
                contact_phone: props.userInfo.contact_phone? props.userInfo.contact_phone: "",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
              }) => (
                <Form onSubmit={handleSubmit} className="w-100">
                  <div className='section-row-1'>
                    <span className='narrator'>{t('Profile ContactDetails')}</span>
                      <Form.Row className="mt-2">
                        <Col xs={4}>
                          <InputGroup className='h-100'>
                            <Form.Control 
                              as="select" 
                              aria-label="Default select example"
                              className='h-100'
                              onChange={e => setPhonePrefix(e.target.value)}
                              value={phonePrefix}
                            >
                              {
                                CountryList.sort(function(a, b){
                                  if(a.name === 'Malaysia' || a.name === 'Singapore'){
                                    return -1
                                  }else{
                                    return a.name - b.name
                                  }
                                }).map((v, i) => {
                                  return (
                                    <option key={i} value={v.dial_code}>
                                      {v.flag + " " + v.dial_code}
                                    </option>
                                  )
                                })
                              }
                            </Form.Control>
                        </InputGroup>
                      </Col>
                      <Col xs={8}>
                        <Form.Control
                          type="text"
                          name="contact_phone"
                          value={values.contact_phone}
                          onChange={handleChange}
                          isInvalid={!!errors.contact_phone}
                          placeholder={t('Profile ContactNumber Placeholder')}
                        />
                        <Form.Control.Feedback type="invalid" className='input-group-feedback'>{errors.contact_phone}</Form.Control.Feedback>
                      </Col>
                    </Form.Row>
                  </div>
                  <div className='w-100 text-center'>
                    <button type="submit" className='btn-update'>{t('Profile UpdateButton')}</button>
                  </div>
                </Form>
              )}
            </Formik>

            <hr className='w-100' />
            <Formik
              enableReinitialize
              validationSchema={PasswordSchema}
              onSubmit={onUpdatePassword}
              initialValues={{
                password: "",
                passwordConfirmation: ""
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
              }) => (
                <Form onSubmit={handleSubmit} className="w-100">
                  <div className='section-row-1'>
                    <span className='narrator'>{t('Profile ChangePassword')}</span>
                    <Form.Control
                      type="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                      placeholder={t('Profile Password Placeholder')}
                    />
                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                  </div>
                  <div className='section-row-1'>
                    <Form.Control
                      type="password"
                      name="passwordConfirmation"
                      value={values.passwordConfirmation}
                      isInvalid={!!errors.passwordConfirmation}
                      onChange={handleChange}
                      placeholder={t('Profile ConfirmPassword Placeholder')}
                    />
                    <Form.Control.Feedback type="invalid">{errors.passwordConfirmation}</Form.Control.Feedback>
                  </div>
                  <div className='w-100 text-center'>
                    <button type="submit" className='btn-update'>{t('Profile UpdateButton')}</button>
                  </div>
                </Form>
              )}
            </Formik>

            <hr className='w-100' />

            <Formik
              enableReinitialize
              onSubmit={updateLanguage}
              initialValues={{
                language: props.userInfo.language ? props.userInfo.langauge : "en",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                errors,
              }) => (
                <Form onSubmit={handleSubmit} className="w-100">
                  <div className='section-row-1'>
                    <span className='narrator'>{t('Profile ChangeLanguage')}</span>
                  </div>
                  <div className='section-row-2'>
                    <Select
                      className='w-100'
                      defaultValue={props.userInfo.selectedLanguage}
                      onChange={(e) => [setSelectedLanguage(e), localStorage.setItem('selectedLanguage', e)]}
                    >
                      <Option value="en">🇬🇧  English</Option>
                      <Option value="ms">🇲🇾  Bahasa Melayu</Option>
                      <Option value="zhcn">🇨🇳  简体中文</Option>
                      <Option value="zhcht">🇹🇼  繁體中文</Option>
                    </Select>
                  </div>
                  <div className='w-100 text-center'>
                    <button type="submit" className='btn-update'>{t('Profile UpdateButton')}</button>
                  </div>
                </Form>
              )}
            </Formik>
{/* 
            <Form.Row className="mb-3">
                        <Col md={12}>
                          <InputGroup>
                            <InputGroup.Text className='facebook'><i className="fab fa-facebook"></i></InputGroup.Text>
                            <Form.Control
                              type="text"
                              name="facebook"
                              className='social-input'
                              value={values.facebook}
                              onChange={handleChange}
                              placeholder="Enter your Facebook account"
                            />
                          </InputGroup>
                        </Col>
                      </Form.Row>
                      <Form.Row className="mb-3">
                        <Col md={12}>
                          <InputGroup>
                            <InputGroup.Text className='twitter'><i className="fab fa-twitter"></i></InputGroup.Text>
                            <Form.Control
                              type="text"
                              name="twitter"
                              className='social-input'
                              value={values.twitter}
                              onChange={handleChange}
                              placeholder="Enter your Twitter account"
                            />
                          </InputGroup>
                        </Col>
                      </Form.Row>
                      <Form.Row>
                        <Col md={12}>
                        <InputGroup>
                          <InputGroup.Text className='google'><i className="fab fa-google"></i></InputGroup.Text>
                          <Form.Control
                            type="text"
                            name="google"
                            className='social-input'
                            value={values.google}
                            onChange={handleChange}
                            placeholder="Enter your Google account"
                          />
                        </InputGroup>
                        </Col>
                      </Form.Row>
                  </div>
                  <div className='w-100 text-center'>
                    <button type="submit" className='btn-update'>Update</button>
                  </div>
                </Form>
              )}
            </Formik> */}

            <hr className='w-100' />
            <div className='section-row-1'>
              <span className='narrator'>{t('Profile LoginDetails')}</span>
              <RenderSocialLogin />
            </div>

            <hr className='w-100' />

            <div className='section-row-1 mt-4 text-center'>
              <span className='narrator-1'>{t('Profile MerchantsSaved')}</span>
              <div className='registered-merchants'>
                {
                  savedMerchants.map((merchant, index) =>
                    <div className='merchant' key={index}>
                      <div className='merchant-name'>
                        <img src={merchantShop} alt="" />
                        <span className='text-capitalize'>{merchant.merchantName}</span>
                      </div>
                      <button 
                        className='action' 
                        onClick={() => props.history.push(`/${merchant.merchantName}`) }
                      >
                          Visit
                      </button>
                    </div>
                )
                }
              </div>
            </div>

            <div className='section-row-1 mt-4 text-center'>
              <span className='narrator-1'>{t('Profile RegisteredMerchants')}</span>
              <div className='registered-merchants'>
                {
                  visitList.map((merchant, index) =>
                    <div className='merchant' key={index}>
                      <div className='merchant-name'>
                        <img src={merchantShop} alt="" />
                        <span className='text-capitalize'>{merchant.merchantName}</span>
                      </div>
                      <button 
                        className='action' 
                        onClick={() => props.history.push(`/${merchant.merchantName}`) }
                      >
                          Visit
                      </button>
                    </div>
                )
                }
              </div>
            </div>
            <button className='btn-logout w-100' onClick={() => {
              props.signout()
              props.history.push(`/${props.merchant.username}`);
            }}>{t('Profile Logout')}</button>

          </div>
        </div>
      </div>

      <ModalConfirmation 
        modalVisible={modalVisibleEmail}
        toggleModal={toggleModalEmail}
        header={
          <>
            <span className='title1 text-center'>
              <br />
              Verify New Email
            </span>
          </>
        }
        body={
          <>
              <div>
              <p className='m-0 p-0'>We emailed you the four digit code to your new email <b>{tmpValues.email}</b></p>
              <p className='m-0 p-0' style={{fontSize: 12}}>Enter the code below to confirm your email address.</p>
              <PinInput 
                length={4} 
                initialValue=""
                type="numeric" 
                inputMode="number"
                style={{padding: '10px'}}  
                inputStyle={{borderColor: 'red'}}
                inputFocusStyle={{borderColor: 'blue'}}
                onComplete={(value, index) => handleVcodeDone(value, index)}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
              </div>
          </>
        }
        noFooter={true}
      />

      <ModalConfirmation 
        modalVisible={modalVisible}
        toggleModal={toggleModal}
        header={
          <>
            <span className='title1 text-center'>
              <br />
              {
                passwordAction === 'new'?
                "Set new password":
                "Verify Password"
              }
            </span>
          </>
        }
        body={
          <>
            <span className='title2'>
              {
                passwordAction === 'new'?
                (
                  <div>
                    <Formik
                      enableReinitialize
                      validationSchema={PasswordSchema}
                      onSubmit={updateNewPassword}
                      initialValues={{
                        password: "",
                        passwordConfirmation: ""
                      }}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        values,
                        errors,
                      }) => (
                        <Form onSubmit={handleSubmit} className="w-100">
                          <div className='section-row-1'>
                            <Form.Control
                              type="password"
                              name="password"
                              value={values.password}
                              onChange={handleChange}
                              isInvalid={!!errors.password}
                              placeholder="Your Password"
                            />
                            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                          </div>
                          <div className='section-row-1'>
                            <Form.Control
                              type="password"
                              name="passwordConfirmation"
                              value={values.passwordConfirmation}
                              isInvalid={!!errors.passwordConfirmation}
                              onChange={handleChange}
                              placeholder="Confirm Password"
                            />
                            <Form.Control.Feedback type="invalid">{errors.passwordConfirmation}</Form.Control.Feedback>
                          </div>
                          <div className='w-100 d-flex justify-content-center mt-2'>
                            <button type="button" onClick={toggleModal} className='btn-default mr-2'>{t('Profile CancelButton')}</button>
                            <button type="submit" className='btn-primary ml-2'>{t('Profile UpdateButton')}</button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                )
                :
                (
                  <div>
                    <Formik
                      enableReinitialize
                      validationSchema={Yup.object().shape({
                        passwordVerify: Yup.string()
                          .required('Please Enter your password'),
                          // .matches(
                          //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                          //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                          // ),
                      })}
                      onSubmit={verifyPassword}
                      initialValues={{
                        passwordVerify: "",
                      }}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        values,
                        errors,
                      }) => (
                        <Form onSubmit={handleSubmit} className="w-100">
                          <div className='section-row-1'>
                            <Form.Control
                              type="password"
                              name="passwordVerify"
                              value={values.passwordVerify}
                              onChange={handleChange}
                              isInvalid={!!errors.passwordVerify}
                              placeholder="Your Password"
                            />
                            <Form.Control.Feedback type="invalid">{errors.passwordVerify}</Form.Control.Feedback>
                          </div>
                          <div className='w-100 d-flex justify-content-center mt-2'>
                            <button type="button" onClick={toggleModal} className='btn-default mr-2'>{t('Profile CancelButton')}</button>
                            <button type="submit" className='btn-primary ml-2'>{t('Profile UpdateButton')}</button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                )
              }
            </span>
          </>
        }
        noFooter={true}
      />

      <Modal
        title='Drag or Resize to get right image'
        visible={modalVisibleCropper}
        closable={false}
        maskClosable={false}
        // wrapClassName="brand-form"
        centered
        mask={true}
        onOk={() => {
          getCropData()
          setModalVisibleCropper(false)
        }}
        onCancel={() => setModalVisibleCropper(false)}
        className='modal-edit-image'
      >
        <div style={{ width: "100%" }}>
          <Cropper
            style={{ height: 400, width: "100%" }}
            // zoomTo={0.5}
            aspectRatio={1/1}
            // initialAspectRatio={3/1}
            className={"circularCrop"}
            preview=".img-preview"
            src={logoImage}
            viewMode={1}
            // zoomOnWheel={false}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            guides={true}
          />
        </div>
      </Modal>
    </ProfileContainer>
  );
}


const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  updateDetails: updateDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
