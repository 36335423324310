import { useRef, useEffect, useState, useContext } from 'react';
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import QRCode from "react-qr-code";
import randomstring from  'randomstring';
import { WalletContainer } from "./style";
import { DataContext } from "contexts/DataContextContainer";
import {
  FaTimesCircle
} from "react-icons/fa";
import { get_campaignDefs } from "functions.js"
// import { CampaignWalletRed } from "assets/images";
import { AuthSuccess, AuthFail } from "assets/images"
import {connect} from 'react-redux';
import { getBalance } from 'redux/actions/roleActions';
import getSymbolFromCurrency from 'currency-symbol-map'
import { InputGroup } from "react-bootstrap";
import TopNav from "components/TopNav/index"

import axios from "axios";
import client from "feathers.js"

import { useTranslation as translation } from 'react-i18next';
import 'services/i18'

const Wallet = props => {
  var color1 = "#3B7779"
  var color2 = "#FFB84F"

  const {t} = translation();

  const history = useHistory();
  const timer = useRef(null);
  const { setLoading, showToast } = useContext(DataContext)
  const [showAuthModal, setShowAuthModal] = useState(false)
  const [showTransactionResultModal, setShowTransactionResultModal] = useState(false)
  const [pin, setPin] = useState('')
  /* eslint-disable-next-line */
  const [authTimer, setAuthTimer] = useState(0)
  /* eslint-disable-next-line */
  const [transactionResult, setTransactionResult] = useState(null)
  const [ campaignDef ] = useState(get_campaignDefs('')[5])
  const [ amount, setAmount ] = useState(0)

  useEffect(() => {

    if(!props.merchant.business_verified){
      showToast('error', "Merchant did not verify business yet!")
      setTimeout(() => {
        props.history.push(`/${props.merchant.username}`) 
      }, 2000);
      return false
    }

    client.authenticate()
    .then((auth) => {
      return axios.post(`${client.io.io.uri}getWalletBalance`, {
        userId: props.userInfo._id,
        merchantId: props.merchant._id,
        currency: props.merchant.currency
      }, {
        headers: {
          'Authorization': auth.accessToken
        }
      })
    })
    .then((res) => {
      props.getBalance(res.data.balance)
    })
    .catch((err)=>{
      console.log(err)
      props.getBalance(0)
      if(err.name === "NotAuthenticated"){  
        // props.history.push(`/${props.merchant.username}`)
        props.toggleLoginModal()
      }
      // showToast('error', err.message)
      // setLoading(false)
    })
    /* eslint-disable-next-line */
  }, [])

  const back = () => {
    history.goBack()
  }

  const toTopup = () => {
    client.authenticate()
    .then(() => {
      props.history.push(`/${props.merchant.username}/topup`)
    })
    .catch((err)=>{
      console.log(err.message)
      props.toggleLoginModal()
    })
  }

  const payWithWallet = () => {
    // setLoading(true)
    if(amount <= 0){
      showToast('error', t('Wallet AmountError'))
      return false
    }
    
    if(amount >= props.balance){
      showToast('error', 'Insufficient balance!')
    }else{
      const _pin = randomstring.generate({length: 4, charset: 'numeric'})

      client.authenticate()
        .then(() => {
          return client.service('activities').create({
            title: "Pay at Counter",
            customer_email: props.userInfo.email,
            merchantId: props.merchant._id,
            merchantName: props.merchant.username,
            currency: props.merchant.currency,
            campaignType: "wallet",
            paymentType: "wallet",
            creditDebit: "debit",
            amount: amount, 
            pin: _pin, 
          })
        })
        .then((res) => {
  
          setShowAuthModal(true)
          setLoading(false)
          setPin(_pin)
  
          if (timer?.current) {
            clearInterval(timer.current);
          }
  
          setAuthTimer(60)
          timer.current = setInterval(() => {
            console.log(authTimer)
  
            setAuthTimer(preVal => {
              if (preVal > 1) {
                return preVal - 1
              } else {
                setShowAuthModal(false)
                setPin('')
                if (timer?.current) {
                  clearInterval(timer.current);
                  timer.current = null
                }
                return 0
              }
             });
          }, 1000)
  
          getWalletAuthResponse(res._id, _pin)
  
        })
        .catch((err)=>{
          if(err.name === "NotAuthenticated"){
        
          }else{
            showToast('error', err.message)
          }
          props.toggleLoginModal()
          setLoading(false)
        })
    }
  }

  const getWalletAuthResponse = async(activityId, _pin) => {

    const result = await client.service('activities').get(activityId)

    if (result.status === 'unauthorized') {
      if (timer?.current) {
        setTimeout(() => {
          getWalletAuthResponse(activityId, _pin)
        }, 1500)
      }
    } else if (result.status === 'authorized') {

      setLoading(false)

      props.getBalance(Number(props.balance) - Number(result.amount))

      setShowAuthModal(false)
      setPin('')
      if (timer?.current) {
        clearInterval(timer.current);
        timer.current = null
      }

      setTransactionResult('success')
      setShowTransactionResultModal(true)

    } else if (result.status === 'declined') {
      setShowAuthModal(false)
      setPin('')
      if (timer?.current) {
        clearInterval(timer.current);
        timer.current = null
      }

      setTransactionResult('fail')
      setShowTransactionResultModal(true)
    }
  }

  const onChangeAmount = (e) => {
    //replace non-digits with blank
    const value = e.target.value
    // .replace(/[^\d]/,'');

    // if(parseInt(value) !== 0) {
      setAmount(Number(value))
    // }
  }

  console.log('C Name: ', campaignDef.name)

  return (
    <WalletContainer color1={color1} color2={color2}
    onWheel={ event => {
      if (event.nativeEvent.wheelDelta > 0) {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('down')
          document.getElementById('tab-wrap').classList.add('up')
        }
      } else {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('up')
          document.getElementById('tab-wrap').classList.add('down')
        }
      }
    }}
    >
      <div className='wallet-container' id="wallet-container">
        <TopNav 
          name={t(`Home ${campaignDef.name}`)}
          back={back}
          merchant={props.merchant}
          userInfo={props.userInfo}
          signout={props.signout}
          toggleLoginModal={props.toggleLoginModal}
        />

        <div className='wallet-page-body'>
          <div className='balance-top-up-wrap'>
            <div className='balance-wrap'>
              <p>{t('Wallet CurrentBalance')}</p>
              <span className='balance'>{getSymbolFromCurrency(props.merchant.currency) + props.balance.toFixed(2)}</span>
            </div>
            <div>
              <button className='top-up shadow' onClick={toTopup}>{t('Wallet Topup')}</button>
            </div>
          </div>
          
          <div className='wallet-wrapper'>
            <div className='enter-amount'>
              <div className='w-100'>
                <p>{t('Wallet EnterAmount')}</p>
              </div>
              <div className="amount-wrap row no-gutters"> 
                <div className='col-md-12'>
                  <InputGroup className='justify-content-center'>
                    <InputGroup.Text style={{
                      background: 'transparent',
                      border: 'none',
                      fontWeight: 'bold',
                      fontSize: 28,
                      marginBottom: -24
                    }}>{getSymbolFromCurrency(props.merchant.currency)}</InputGroup.Text>
                  </InputGroup>
                </div>
                <div className='col-md-12'>
                  <input 
                    className='amount' 
                    type="number" 
                    step=".01" 
                    onChange={onChangeAmount} 
                    onWheel={ event => event.currentTarget.blur() }
                    // value={Number(amount).toString()} 
                    value={(amount)} 
                  />
                </div>
              </div>
            </div>
            <div className='pay-with-wallet'>
              <button onClick={payWithWallet}>{t('Wallet WalletPayment')}</button>
              <span onClick={() => props.history.push('/' + props.merchant.username + '/transactions')}>{t('Wallet PreviousTransaction')}</span>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={false}
        visible={showAuthModal}
        onCancel={() => {
          setShowAuthModal(false)
          setPin('')
          if (timer?.current) {
            clearInterval(timer.current);
            timer.current = null
          }
        }}
        centered
        closable={true}
        footer={false}
        maskClosable={false}
        getContainer={() => document.getElementById("wallet-container")}
        width='80%'
        closeIcon={<FaTimesCircle className='close-icon'/>}
      >
        <div className='auth-modal'>
          <span className='title1'>Authentication</span>
          <span className='title2'>Let merchant<br/>scan this QR code</span>
          <QRCode value={pin} size={200} />
          <span className='title3'>Or let merchant enter the PIN</span>
          <span className='title4'>PIN : <span className='title5'>{pin}</span></span>
          <span className='title6'>Time remaining</span>
          <span className='title7'>00 : {authTimer < 10 ? `0${authTimer}` : authTimer}</span>
        </div>
      </Modal>

      <Modal
        title={false}
        visible={showTransactionResultModal}
        onCancel={() => {
          setShowTransactionResultModal(false)
        }}
        centered
        closable={true}
        footer={false}
        maskClosable={false}
        getContainer={() => document.getElementById("wallet-container")}
        width='80%'
        closeIcon={<FaTimesCircle className='close-icon'/>}
      >
        <div className='auth-modal'>
          {/* <span className='title1'>Authentication</span> */}
          {
            transactionResult === 'success' ? <img src={AuthSuccess} alt="" /> : <img src={AuthFail} alt="" />
          }
          {/* <span className={transactionResult === 'success' ? 'title8 success' : 'title8 fail'}>{transactionResult === 'success' ? 'Transaction is' : 'Transaction is'}</span> */}
          <br/>
          <span className={transactionResult === 'success' ? 'title8 success' : 'title8 fail'}>{transactionResult === 'success' ? 'Successful' : 'Unsuccessful'}</span>
          <button onClick={() => setShowTransactionResultModal(false)} className={transactionResult === 'success' ? 'btn-success' : 'btn-danger'}>Done</button>
        </div>
      </Modal>
    </WalletContainer>
  );
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  getBalance: getBalance
};

export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
