import { useState, useLayoutEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { LoginContainer } from "./style";
import PinInput from 'react-pin-input';
import randomstring from  'randomstring';
import { Col, Form } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { 
  Button,
} from "react-bootstrap";
import StyledButton from "components/Elements/StyledButton";
import { connect } from 'react-redux'
import { loginDetails } from 'redux/actions/roleActions';
import { DataContext } from "contexts/DataContextContainer";
import SocialButton from "./SocialButton";
import { useGoogleLogin } from '@react-oauth/google';
import CountryList from 'country-list-with-dial-code-and-flag'
import { Select } from 'antd';

import { Modal } from "antd";
import {
  FaTimesCircle
} from "react-icons/fa";
import { FacebookIcon, GoogleIcon, WhatsappIcon, EmailIcon } from "assets/images"

import env from "env"
import axios from 'axios';
import client from "feathers.js"

import { useTranslation as translation, Trans } from 'react-i18next';
import 'services/i18';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const Login = props => {
  const {t} = translation();

  const history = useHistory();
  const { setLoading, showToast, controlFailModal } = useContext(DataContext);
  const [ verify, setVerify ] = useState(false)
  const [ credentials, setCredentials ] = useState({})
  const [ loginMethod, setLoginMethod ] = useState('email')
  const [ verifyCode, setVerifyCode ] = useState('')
  const [ phonePrefix, setPhonePrefix ] = useState('SG');
  const [ countrylists, setCountrylists ] = useState([]);

  const isLogin = props.isLogin
  const setIsLogin = props.setIsLogin

  useLayoutEffect(() => {
    if(props.verifying){
      setIsLogin(false)
      setVerify(true)
    }
    /* eslint-disable-next-line */
  }, [props.verifying])

  useLayoutEffect(() => {
    setCountrylists(CountryList.sort(function(a, b){
        if(a.name === 'Malaysia' || a.name === 'Singapore'){
          return -1
        }else{
          return a.name - b.name
        }
      }).map((v, i) => {
        v.label = v.flag + " " + v.dial_code
        v.value = v.code
        return v
    }))
  }, [])

  const SignupSchema = () => {
    if(loginMethod === 'whatsapp'){
      return Yup.object().shape({
        contact_phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Required!"),
        firstName: Yup.string().required("Required!"),
        lastName: Yup.string().required("Required!"),
        password: Yup.string().required("Required!"),
        confirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords does not match'),
        toggle: Yup.bool() // use bool instead of boolean
        .oneOf([true], "You must accept the terms and conditions")
      });  
    }
    return Yup.object().shape({
      email: Yup.string().email().required("Required!"),
      firstName: Yup.string().required("Required!"),
      lastName: Yup.string().required("Required!"),
      password: Yup.string().required("Required!"),
      confirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords does not match'),
      toggle: Yup.bool() // use bool instead of boolean
      .oneOf([true], "You must accept the terms and conditions")
    });
  }
  
  const LoginSchema = () => {
    if(loginMethod === 'whatsapp'){
      return Yup.object().shape({
        contact_phone: Yup.string().required("Required!"),
        password: Yup.string().required("Required!")
      });   
    }
    return Yup.object().shape({
      email: Yup.string().email().required("Required!"),
      password: Yup.string().required("Required!")
    });
  }

  const customerAccountRegisteredSuccessful = (res, open) => {
    if(props.refLinkId){
      axios.post(`${client.io.io.uri}customerAccountRegisteredSuccessful`, {
        refLinkId: props.refLinkId,
        userId: res._id,
        merchantId: props.merchant._id,
      })
      .then(res => {
        if(res.status === 200){
          // showToast('success', 'You have received Referral Voucher!')

          if(open){
            props.toggleSuccessModal()
          }
          
        }else{
          showToast('error', 'Referral ID Not Found!')
        }
      })
      .catch((err)=>{
        console.log(err)
        showToast('error', 'Referral ID Not Found!')
        // setLoading(false)
      })
    }
  }

  const checkGoogleIfRedeemed = async(data, response) => {
    let result = true;
    if(props.refLinkId){
      await client.service('users').find({
        query: {
          merchantId: props.merchant._id,
          googleId: data.id
        },
      })
      .then((res1) => {
        if(res1.data.length > 0){
          showToast('error', "Opps... you are not eligible to claim the reward. It appears that you already has an account with us.", (
            <Button 
              variant="primary" 
              className='w-100'
              onClick={() => {
                controlFailModal(false)
                googleLogin(data, response)
              }}
            >
              Sign In
            </Button> 
          ))
          props.toggleLoginModal()
          result = false
        }else{
          result = true
        }
      })
    }
    return result
  }

  const checkFacebookIfRedeemed = async(data, response) => {
    let result = true;
    if(props.refLinkId){
      await client.service('users').find({
        query: {
          merchantId: props.merchant._id,
          facebookId: data.id
        },
      })
      .then((res1) => {
        if(res1.data.length > 0){
          showToast('error', "Opps... you are not eligible to claim the reward. It appears that you already has an account with us.", (
            <Button 
              variant="primary" 
              className='w-100'
              onClick={() => {
                controlFailModal(false)
                facebookLogin(data, response)
              }}
            >
              Sign In
            </Button> 
          ))
          props.toggleLoginModal()
          result = false
        }else{
          result = true
        }
      })
    }
    return result
  }

  const handleLogin = async(value, created) => {

    
    if(loginMethod === 'whatsapp'){

      value.phonePrefix = CountryList.find(x => x.code === phonePrefix)?.dial_code || '+65'

      client.authenticate({
        strategy: 'local-phone', 
        username: (value.phonePrefix?.replace(/^\+/, ''))?.trim() + value.contact_phone?.trim(),
        merchantId: props.merchant._id,
        password: value.password
      })
      .then((res)=>{
        let lastLogin = new Date()
        // res.user.lastLogin = lastLogin
  
        if(created){
          res.created = true
        }
        props.loginDetails(res)
  
        client.service('users').patch(res.user._id, {
          lastLogin,
        })

        localStorage.setItem('selectedLanguage', res.selectedLanguage)
  
        setTimeout(() => {
          setLoading(false)
          props.toggleLoginModal()
          if(props.refLinkId){
            history.push(`/${props.merchant.username}/voucher?view=true`)
          }
        }, 
          500
        );
      })
      .catch((err)=>{
        console.log(err)
        // if(err.name === "NotAuthenticated"){
          
        // }else{
        // }
        // invalid login is NotAuthenticated
        if(err.message.includes("Invalid login")){
          showToast('error', "Phone and password do not match")
        }else if(err.message.includes('It seems that you have not sign up with us before')){
          showToast('error',
          (
            <>
              <p style={{fontWeight: 500, marginBottom: '0.6rem'}}>Invalid login</p>
              <p>It seems that you have not sign up with us before.</p>
              <Button 
                variant="primary" 
                className='w-100'
                onClick={() => {
                  setIsLogin(false)
                  controlFailModal(false)
                }}
              >
                Sign Up
              </Button> 
            </>
          ))  
        }else{
          showToast('error', err.message)
        }
        setLoading(false)
      })
    }else{
      client.authenticate({
        strategy: 'local', 
        email: value.email, 
        merchantId: props.merchant._id,
        password: value.password
      })
      .then((res)=>{
        let lastLogin = new Date()
        // res.user.lastLogin = lastLogin
  
        if(created){
          res.created = true
        }
        props.loginDetails(res)
  
        client.service('users').patch(res.user._id, {
          lastLogin,
        })
        
        localStorage.setItem('selectedLanguage', res.selectedLanguage)

        setTimeout(() => {
          setLoading(false)
          props.toggleLoginModal()
          if(props.refLinkId){
            history.push(`/${props.merchant.username}/voucher?view=true`)
          }
        }, 
          500
        );
      })
      .catch((err)=>{
        console.log(err)
        // if(err.name === "NotAuthenticated"){
          
        // }else{
        // }
        // invalid login is NotAuthenticated
        if(err.message.includes("Invalid login")){
          showToast('error', "Email and password do not match")
        }else if(err.message.includes('It seems that you have not sign up with us before')){
          showToast('error',
          (
            <>
              <p style={{fontWeight: 500, marginBottom: '0.6rem'}}>Invalid login</p>
              <p>It seems that you have not sign up with us before.</p>
              <Button 
                variant="primary" 
                className='w-100'
                onClick={() => {
                  setIsLogin(false)
                  controlFailModal(false)
                }}
              >
                Sign Up
              </Button> 
            </>
          ))  
        }else{
          showToast('error', err.message)
        }
        setLoading(false)
      })
    }
  }

  const loginOrSignup = async (value, { resetForm }) => {
    if(isLogin){

      handleLogin(value)

    }else{

      setLoading(true)

      // value.set_done = 'done'

      value.vcode = randomstring.generate({length: 4, charset: 'numeric'})
      value.isReferral = props.refLinkId? true: false // if is Referral
      value.merchantId = props.merchant._id
      value.merchantName = props.merchant.username
      value.maxUsers = env.subscription[props.merchant.subscription || "copper"].customers

      if(loginMethod === 'whatsapp'){
        // send whatsapp
        value.phonePrefix = CountryList.find(x => x.code === phonePrefix)?.dial_code || '+65'

        client.service('verifications').create({
          contact_phone: value.contact_phone,
          phonePrefix: value.phonePrefix,
          type: 'whatsapp',
          code: value.vcode
        })
        .then(res => {
          setCredentials(value)
          setVerify(true)
          setLoading(false)
        }).catch(err => {
          console.log(err)
          setLoading(false)
        })
      }else{
        client.service('users').create(value)
        .then((res) => {
          resetForm()
  
          // If account created
          if(res.created){
            customerAccountRegisteredSuccessful(res, false)
          }
  
          setTimeout(() => {
            setLoading(false)
            setIsLogin(true)
            setVerify(true)
            setCredentials({
              email: value.email, 
              password: value.password
            })
          }, 
            500
          );
        })
        .catch((err)=>{
          if(props.refLinkId && err.message.includes('already been registered')){
            showToast('error', "Opps... you are not eligible to claim the reward. It appears that you already has an account with us.", (
              <Button 
                variant="primary" 
                className='w-100'
                onClick={() => {
                  controlFailModal(false)
                  setIsLogin(true)
                  // props.toggleLoginModal()
                }}
              >
                Sign In
              </Button> 
            )) 
          }else if(err.name === "NotAuthenticated"){
          
          }else{
            if(err.message.includes('Your registration is not successful')){
              showToast('error', (
                <>
                  <p style={{fontWeight: 500, marginBottom: '0.6rem'}}>Oops! Your registration is not successful.</p>
                  <p>Merchant has reached the maximum quota for new customer registration.</p>
                  <p>Please contact merchant.</p>
                </>
              ))
            }else{
              showToast('error', err.message)
            }
          }
          setLoading(false)
        })
      }

    }
  }

  const loginGoogle = useGoogleLogin({
    onSuccess: tokenResponse => responseGoogle(tokenResponse),
  });

  const responseGoogle = async(response) => {
    setLoading(true)

    let getData = await axios.get('https://www.googleapis.com/oauth2/v1/userinfo', {
      headers: {
        authorization: `Bearer ${response.access_token}`
      },
    });
    
    let data = getData.data
    data.isReferral = props.refLinkId? true: false // if is Referral
    data.merchantId = props.merchant._id
    data.merchantName = props.merchant.username
    data.maxUsers = env.subscription[props.merchant.subscription || "copper"].customers

    data.action = isLogin?"login":"create"
    
    // modify data
    data.googleId = data.id
    data.firstName = data.family_name
    data.lastName = data.given_name
    data.profilePicURL = data.picture
    
    if(await checkGoogleIfRedeemed(data, response)){
      googleLogin(data, response)
    } else {
      setLoading(false);
    }
  }

  const googleLogin = (data, response) => {
    client.authenticate({
      strategy:'google',
      data: data,
      access_token: response.access_token,
    })
    .then((res)=>{

      let lastLogin = new Date()
      res.user.lastLogin = lastLogin

      props.loginDetails(res)

      client.service('users').patch(res.user._id, {
        lastLogin,
      })

      // If account created
      if(res.user.created){
        customerAccountRegisteredSuccessful(res.user, true)
      }

      setTimeout(() => {
        setLoading(false)
        props.toggleLoginModal()
      }, 
        500
      );
    })
    .catch((err)=>{
      console.log(err)
      // (err.message.includes("already been registered"))
      // (err.message === "Invalid login")
      if(err.name === "NotAuthenticated"){
       // invalid login is NotAuthenticated
        if(data.action === "create"){

          function switchToLogin(){
            
            data.action = "login"

            controlFailModal(false)
            googleLogin(data, response)
          }

          showToast('error', "It appears that you already has an account with us.", (
            <Button 
              variant="primary" 
              className='w-100'
              onClick={() => {
                switchToLogin()
              }}
            >
              Sign In Now
            </Button> 
          )) 
        }else{

          function switchToSignup(){
            
            data.action = "create"

            controlFailModal(false)
            googleLogin(data, response)
          }

          showToast('error', "You do not have an account with us!", (
            <Button 
              variant="primary" 
              className='w-100'
              onClick={() => {
                switchToSignup()
              }}
            >
              Sign Up Now
            </Button> 
          )) 
        }
      }else{
        if(err.message.includes('Your registration is not successful')){
          showToast('error', (
            <>
              <p style={{fontWeight: 500, marginBottom: '0.6rem'}}>Oops! Your registration is not successful.</p>
              <p>Merchant has reached the maximum quota for new customer registration.</p>
              <p>Please contact merchant.</p>
            </>
          ))
        }else{
          showToast('error', err.message)
        }
      }
      setLoading(false)
    })
  }

  const responseFacebook = async(response) => {
    const { data } = await axios.get('https://graph.facebook.com/me', {
      headers: {
        authorization: `Bearer ${response._token.accessToken}`
      },
      params: {
        // There are 
        fields: 'id,name,email,picture,birthday,about'
      }
    });

    data.firstName = response._profile.firstName
    data.lastName = response._profile.lastName
    data.profilePicURL = response._profile.profilePicURL
    
    data.isReferral = props.refLinkId? true: false // if is Referral
    data.merchantId = props.merchant._id
    data.merchantName = props.merchant.username
    data.maxUsers = env.subscription[props.merchant.subscription || "copper"].customers
    
    data.action = isLogin?"login":"create"
 
    if(await checkFacebookIfRedeemed(data)){
      facebookLogin(data)
    }
  }
 
  const facebookLogin = async(data) => {
    client.authenticate({
      strategy:'facebook',
      // access_token: response.accessToken,
      data: data
    })
    .then((res)=>{

      let lastLogin = new Date()
      res.user.lastLogin = lastLogin

      props.loginDetails(res)

      client.service('users').patch(res.user._id, {
        lastLogin,
      })

      // If account created
      if(res.user.created){
        customerAccountRegisteredSuccessful(res.user, true)
      }

      setTimeout(() => {
        setLoading(false)
        props.toggleLoginModal()
      }, 
        500
      );
    })
    .catch((err)=>{
      console.log(err)
      if(err.name === "NotAuthenticated"){
        // invalid login is NotAuthenticated
         if(data.action === "create"){
 
           function switchToLogin(){
             
            data.action = "login"

            controlFailModal(false)
            facebookLogin(data)
           }
 
           showToast('error', "It appears that you already has an account with us.", (
             <Button 
               variant="primary" 
               className='w-100'
               onClick={() => {
                 switchToLogin()
               }}
             >
                Sign In Now
             </Button> 
           )) 
         }else{
 
           function switchToSignup(){
             
            data.action = "create"

            controlFailModal(false)
            facebookLogin(data)
           }
 
           showToast('error', "You do not have an account with us!", (
             <Button 
               variant="primary" 
               className='w-100'
               onClick={() => {
                 switchToSignup()
               }}
             >
               Sign Up Now
             </Button> 
           )) 
         }
       }else{
        if(err.message.includes('Your registration is not successful')){
          showToast('error', (
            <>
              <p style={{fontWeight: 500, marginBottom: '0.6rem'}}>Oops! Your registration is not successful.</p>
              <p>Merchant has reached the maximum quota for new customer registration.</p>
              <p>Please contact merchant.</p>
            </>
          ))
        }else{
          showToast('error', err.message)
        }
       }
      setLoading(false)
    })
  }

  const handleVcodeDone = (value, index) => {
    if(loginMethod === 'whatsapp'){
      client.service('verifications').find({
        query: {
          contact_phone: credentials.contact_phone,
          phonePrefix: credentials.phonePrefix,
          code: value,
          verified: false,
          $limit: 1
        }
      })
      .then(res => {
        if(res.data.length > 0){
          
          client.service('users').create({
            ...credentials,
            registerType: 'whatsapp',
            set_done: 'done',
            vcode: "AUTH"
          })
          .then(res1 => {
            client.service('verifications').patch(res?.data?.[0]?._id, {verified: true})

            if(res1.isReferral){
              props.toggleSuccessModal(true)
            }else{
              showToast('success', "You've created an account successfully.")
            }
            // If account created
            if(res1.created){
              customerAccountRegisteredSuccessful(res1, false)
            }
            setTimeout(() => {
              setIsLogin(true)
              setVerify(false)
              setCredentials({})
            }, 2000);
          })
          .catch(err => {
            console.log(err)
            if(err.name === "NotAuthenticated"){
              
            }else{
              showToast('error', err.message)
            }
          })
        }else{
          showToast('error', 'Verification Code is not correct!')
        }
      })
      .catch(err => {
        console.log(err)
        if(err.name === "NotAuthenticated"){
          
        }else{
          showToast('error', err.message)
        }
      })
      return false
    }else{
      client.service('users').find({
        query: {
          email: credentials.email,
          vcode: value,
          $limit: 1
        }
      })
      .then(res => {
        if(res.data.length > 0){
          
          client.service('users').patch(res.data[0]._id, {
            set_done: 'done',
            vcode: "AUTH"
          }).then(res1 => {
            if(res1.isReferral){
              props.toggleSuccessModal(true)
            }else{
              showToast('success', "You've created the account successfully.")
            }
          })
  
          setTimeout(() => {
            setIsLogin(true)
            setVerify(false)
            setCredentials({})
          }, 2000);
        }else{
          showToast('error', 'Verification Code is not correct!')
        }
      })
      .catch(err => {
        console.log(err)
        if(err.name === "NotAuthenticated"){
          
        }else{
          showToast('error', err.message)
        }
      })
    }
  }

  const RenderFacebookComponent = () => {
    const enableComponent = () => {
      if(document.getElementById('btn-facebook')){
        document.getElementById('btn-facebook').remove()
      }
    }

    return (
      <>
        <SocialButton
          provider="facebook"
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          className="btn btn-facebook"
          onLoginSuccess={responseFacebook}
          onLoginFailure={console.log}
          getInstance={() => enableComponent()}
        >
            <img src={FacebookIcon} alt="" />
        </SocialButton>
        <button 
          id="btn-facebook" 
          className="btn btn-facebook"
        >
            <img src={FacebookIcon} alt="" />
        </button>
      </>
    )
  }

  const RenderGoogleComponent = () => {
    // const enableComponent = () => {
    //   if(document.getElementById('btn-google')){
    //     document.getElementById('btn-google').remove()
    //   }
    // }

    return (
      <>
        <button
          // provider="google"
          // appId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          className="btn btn-google"
          onClick={() => loginGoogle()}
          // onLoginSuccess={responseGoogle}
          // onLoginFailure={console.log}
          // getInstance={() => enableComponent()}
        >
          <img src={GoogleIcon} alt="" />
        </button>
        {/* <button 
          id="btn-google" 
          className="btn btn-google"
          disabled
        >
          <img src={GoogleIcon} alt="" />
        </button> */}
      </>
    )
  }
  
  const RenderSocialLogin = () => {
    return (
      <div className='social'>
        <button
          className="btn btn-email"
          onClick={() => setLoginMethod('email')}
        >
          <img src={EmailIcon} alt="" />
        </button>
        <button
          className="btn btn-whatsapp"
          onClick={() => setLoginMethod('whatsapp')}
        >
          <img src={WhatsappIcon} alt="" />
        </button>

        <RenderFacebookComponent />
        <RenderGoogleComponent />
        {/* <button className="btn btn-twitter"><i className="fab fa-twitter"></i> Twitter</button> */}
      </div>
    )
  }

  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
 
  return (
    <Modal
      title={false}
      visible={props.showModal}
      onCancel={() => {
        if(window.location.pathname.includes('wallet') || window.location.pathname.includes('topup')){
          history.push(`/${props.merchant.username}`)
        }
        props.toggleLoginModal()
      }}
      centered
      closable={true}
      footer={false}
      // maskClosable={false}
      // getContainer={() => document.getElementById("login-container")}
      // width='90%'
      className='login-antd-modal full-screen-modal'
      closeIcon={<FaTimesCircle className='close-icon'/>}
    >
      <LoginContainer>
        <div className='login-container'>
          <div className='merchant-logo-wrap'>
            <div>
              <img src={client.io.io.uri + props.merchant.logo} alt="" />
            </div>
            <h3>{props.merchant.business_name}</h3>
            <hr style={{width: 44}} />
          </div>
          {
            verify? (
            <>
              {
                loginMethod === 'whatsapp'?
                <>
                  <h3 className='w-100'>Verify Your Account</h3>
                  <h5 className='m-0 p-0'>We've send verification code to you on Whatsapp the four digit code to <b>{verifyCode}</b></h5>
                  <h5 className='m-0 p-0' style={{fontSize: 12}}>Enter the code below to confirm your Phone Number.</h5>
                </>
                :
                <>
                  <h3 className='w-100'>Verify Your Account</h3>
                  <h5 className='m-0 p-0'>We emailed you the four digit code to <b>{credentials.email || props.email}</b></h5>
                  <h5 className='m-0 p-0' style={{fontSize: 12}}>Enter the code below to confirm your email address.</h5>
                </>
              }
              <PinInput 
                length={4} 
                initialValue=""
                // secret 
                // onChange={(value, index) => {}}
                type="numeric" 
                inputMode="number"
                style={{padding: '10px'}}  
                inputStyle={{borderColor: 'red'}}
                inputFocusStyle={{borderColor: 'blue'}}
                onComplete={(value, index) => handleVcodeDone(value, index)}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
              <div className='my-2 w-100'>
                <p>Already have an account? <b onClick={ e => {
                  setIsLogin(true)
                  setVerify(false)
                  }}>Login</b> now!</p>
              </div>
            </>
            ):
            <>
              {isLogin ? (
              <>
                <h3 className='w-100'>{t('Login Header')}</h3>
                <div className='w-100'>
                  <Trans i18nKey='Login Wording 1' />
                </div>
                <RenderSocialLogin />
              </>
            ) : (
              <>
                <h3 className='w-100'>{t('Signup Header')}</h3>
                <div className='w-100'>
                  <Trans i18nKey='Signup Wording 1' />
                </div>
                <RenderSocialLogin />
              </>
            )}

            <div className='my-2'>
              {
                isLogin?
                <h5 className='email-login-label'>{t('Login Or')}</h5>
                :
                <h5 className='email-login-label'>{t('Login Or')}</h5>
              }
            </div>

            <Formik
              validationSchema={isLogin ? LoginSchema() : SignupSchema()}
              onSubmit={loginOrSignup}
              initialValues={{
                email: '',
                contact_phone: '',
                firstName: '',
                lastName: '',
                password: '',
                confirm: '',
                toggle: false,
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                isValid,
                errors,
                dirty,
              }) => (
                <Form onSubmit={handleSubmit}>
                   {
                    !isLogin &&
                      <>
                        <Form.Row className="mb-2">
                          <Col xs={6}>
                            <Form.Control
                              type="text"
                              name="firstName"
                              value={values.firstName}
                              onChange={handleChange}
                              isInvalid={!!errors.firstName}
                              placeholder={t('Signup FirstName Placeholder')}
                            />
                            <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                          </Col>
                          <Col xs={6}>
                            <Form.Control
                              type="text"
                              name="lastName"
                              value={values.lastName}
                              onChange={handleChange}
                              isInvalid={!!errors.lastName}
                              placeholder={t('Signup LastName Placeholder')}
                            />
                            <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                          </Col>
                        </Form.Row>
                      </>
                    }
                    {
                      loginMethod === "email" &&
                      <Form.Row>
                        <Col md={12}>
                          <Form.Control
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                            placeholder={t('Signup EmailAddress Placeholder')}
                            />
                          <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                        </Col>
                      </Form.Row>
                    }
                    {
                      loginMethod === "whatsapp" &&
                      <Form.Row>
                        <Col xs={4}>
                          <Select
                            showSearch
                            placeholder="Select a person"
                            optionFilterProp="children"
                            id="country_code"
                            className="w-100"
                            onChange={e => setPhonePrefix(e)}
                            value={phonePrefix}
                            // onSearch={onSearch}
                            filterOption={filterOption}
                            options={countrylists}
                          />
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            id="contact_phone"
                              type="text"
                              name="contact_phone"
                              value={values.contact_phone}
                              onChange={handleChange}
                              isInvalid={!!errors.contact_phone}
                              placeholder="Phone Number"
                            />
                          <Form.Control.Feedback type="invalid">{errors.contact_phone}</Form.Control.Feedback>
                        </Col>
                      </Form.Row>
                    }
                    <Form.Row className="mt-2">
                      <Col md={12}>
                        <Form.Control
                          type="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password}
                          placeholder={t('Signup Password Placeholder')}
                        />
                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                      </Col>
                    </Form.Row>
                    {
                      !isLogin &&
                      <>
                        <Form.Row className="mt-2">
                          <Col md={12}>
                            <Form.Control
                              type="password"
                              name="confirm"
                              value={values.confirm}
                              onChange={handleChange}
                              isInvalid={!!errors.confirm}
                              placeholder={t('Signup ConfirmPassword Placeholder')}
                            />
                            <Form.Control.Feedback type="invalid">{errors.confirm}</Form.Control.Feedback>
                          </Col>
                        </Form.Row>
                        <Form.Row className="mt-3">
                          <Col md={12} className="text-center">
                            <label>
                              <Field type="checkbox" name="toggle" className="rounded-checkbox"/>
                              <p className="d-inline ml-2" style={{
                                color: '#3B7779'
                              }}>
                              I accept the {" "}
                              </p>
                              <a href="https://morerewardz.com/terms/" target="_blank" rel="noreferrer" style={{textDecoration: 'underline'}}>Term & Condition</a>
                            </label>
                            <Form.Control.Feedback type="invalid">{errors.toggle}</Form.Control.Feedback>
                          </Col>
                        </Form.Row>
                      </>
                    }
                    {
                      isLogin? (
                      <div className='text-right mt-2'>
                        <p 
                          className='m-0'
                          style={{
                            fontWeight: 400,
                            fontSize: 13,
                            color: '#FFB84F'
                          }}
                          onClick={() => {
                            props.toggleLoginModal()
                            props.toggleForgotPassword()
                          }}
                        >
                          {t('Login Forgot Password')}
                        </p>
                      </div>
                      ): null
                    }
                    <StyledButton
                      sizeh={44}
                      disabled={!(isValid && dirty)}
                      type="submit"
                      className="btn-blue mt-5"
                      radius="7"
                      bgcolor={"#3B7779"}
                      // outlined={"#3B7779"}
                      textTransform="uppercase"
                      weight={700}
                      title={isLogin ? t('Login Login Button') : t('Signup Signup Button')}
                    />
                </Form>
              )}
            </Formik>
            <div className='login-footer mt-2'>
              {
                isLogin ?
                <p>
                  <b onClick={() => setIsLogin(false)}>
                    {t('Login Wording 2')}
                  </b>
                </p>
                :
                <p>                  
                  <b onClick={ e => setIsLogin(true)}>
                    {t('Signup Wording 2')}
                  </b>
                </p>
              }
            </div>
            </> 
          }
        </div>
      </LoginContainer>
    </Modal>
  );
}

const mapStateToProps = state => ({
  auth: state.role.auth,
});

const mapDispatchToProps = {
  loginDetails: loginDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
