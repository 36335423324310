import React, { useContext, useLayoutEffect, useState } from "react";
import { DataContext } from "contexts/DataContextContainer";
import ModalConfirmation from 'components/Extra/ModalConfirmation';
import { Formik } from "formik";
import * as Yup from "yup";
import { Col, Form } from "react-bootstrap";
import StyledButton from "components/Elements/StyledButton";
import CountryList from 'country-list-with-dial-code-and-flag'
import { Select } from 'antd';

import client from "feathers.js"

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const ForgotPasswordCompnent = ({
  merchant,
  showForgotModal,
  setShowForgotModal,
  toggleForgotPassword,
}) => {
  const { setLoading, showToast } = useContext(DataContext);
  const [ phonePrefix, setPhonePrefix ] = useState('SG');
  const [ countrylists, setCountrylists ] = useState([]);
  const [ resetMethod, setResetMethod ] = useState('email')

  const forgotPasswordSchema = () => {
    if(resetMethod === 'whatsapp'){
      return Yup.object().shape({
        contact_phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Required!"),
      });  
    }
    return Yup.object().shape({
      email: Yup.string().email().required("Required!")
    })
  }
  const forgotPassword = async (value, { resetForm }) => {
    setLoading(true)

    if(resetMethod === 'whatsapp'){
      
      value.phonePrefix = CountryList.find(x => x.code === phonePrefix)?.dial_code || '+65'

      client.service('forgot-passwords').create({
        username: (value.phonePrefix?.replace(/^\+/, ''))?.trim() + value.contact_phone?.trim(),
        role: 'user',
        resetType: 'whatsapp',
        merchantId: merchant._id,
        merchantName: merchant.username
      })
      .then((res) => {
        resetForm()
        showToast('success', 'Message sent, Check your inbox shortly.')
        setShowForgotModal(false)
        setLoading(false)
      })
      .catch((err)=>{
        showToast('error', err.message)
        setLoading(false)
      })
    }else{
      client.service('forgot-passwords').create({
        email: value.email,
        role: 'user',
        merchantId: merchant._id,
        merchantName: merchant.username
      })
      .then((res) => {
        resetForm()
        showToast('success', 'Email sent, Check your inbox shortly.')
        setShowForgotModal(false)
        setLoading(false)
      })
      .catch((err)=>{
        showToast('error', err.message)
        setLoading(false)
      })
    }
  }

  const handleResetMethod = (e, value) => {
    e.preventDefault()
    setResetMethod(value)
  }

  useLayoutEffect(() => {
    setCountrylists(CountryList.sort(function(a, b){
        if(a.name === 'Malaysia' || a.name === 'Singapore'){
          return -1
        }else{
          return a.name - b.name
        }
      }).map((v, i) => {
        v.label = v.flag + " " + v.dial_code
        v.value = v.code
        return v
    }))
  }, [])

  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  return (  
    <ModalConfirmation 
      modalVisible={showForgotModal}
      toggleModal={toggleForgotPassword}
      modalCallback={()=>{}}
      className="login-antd-modal"
      header={
        <>
          <span className='title1 text-center'>
            <br />
            Forgot Password
          </span>
        </>
      }
      body={
        <>
        {
          resetMethod === 'whatsapp'?
          <p className='title2' style={{ lineHeight: 1.2 }}>
            Please enter the Phone Number that was used to set up your account. You will receive a message with instructions on how to reset your password.
          </p>
          :
          <p className='title2' style={{ lineHeight: 1.2 }}>
            Please enter the email that was used to set up your account. You will receive an email with instructions on how to reset your password.
          </p>
        }

          <Formik
            validationSchema={forgotPasswordSchema()}
            onSubmit={forgotPassword}
            initialValues={{
              email: '',
              contact_phone: '',
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              isValid,
              errors,
              dirty,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Row>
                  <Col md={12}>
                    {
                      resetMethod === 'whatsapp'?
                      <Form.Row>
                        <Col xs={4}>
                          <Select
                            showSearch
                            placeholder="Select a person"
                            optionFilterProp="children"
                            id="country_code"
                            className="w-100"
                            onChange={e => setPhonePrefix(e)}
                            value={phonePrefix}
                            // onSearch={onSearch}
                            filterOption={filterOption}
                            options={countrylists}
                          />
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            id="contact_phone"
                              type="text"
                              name="contact_phone"
                              value={values.contact_phone}
                              onChange={handleChange}
                              isInvalid={!!errors.contact_phone}
                              placeholder="Phone Number"
                            />
                          <Form.Control.Feedback type="invalid">{errors.contact_phone}</Form.Control.Feedback>
                        </Col>
                      </Form.Row>
                      :
                      <>
                        <Form.Control
                          type="text"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                          placeholder="Enter Email Address"
                        />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                      </>
                    }
                  </Col>
                </Form.Row>
                <div className="pt-1 d-flex justify-content-center" style={{opacity: .7}}>
                  {
                    resetMethod === 'whatsapp'?
                    <a href="/#" onClick={(e) => handleResetMethod(e, 'email')}><p className="mb-0">Reset via email</p></a>
                    :
                    <a href="/#" onClick={(e) => handleResetMethod(e, 'whatsapp')}><p className="mb-0">Register via whatsapp? click here.</p></a>
                  }
                </div>
                <StyledButton
                  sizeh={44}
                  type="submit"
                  className="btn-blue mt-5"
                  radius="7"
                  bgcolor={"#3B7779"}
                  textTransform="uppercase"
                  weight={700}
                  title="Submit"
                />
              </Form>
            )}
          </Formik>
        </>
      }
      noFooter={true}
    />
  );
}
 
export default ForgotPasswordCompnent;