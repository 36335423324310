import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from "react-router-dom";
import { CampaignsContainer, CampaignsProfile } from "./style";
import { Button } from "react-bootstrap";
import isEmpty from "lodash/isEmpty"
import EditIcon from "../../../assets/images/Icons/Group 131.svg"
import moment from "moment"
import { UserLogo } from "assets/images"

import 'services/i18'
import { useTranslation as translation } from 'react-i18next'

import client from "feathers.js"

const CampaignDefsComponent = (props) => {
  if(props.campaign.available){
    return (
      <a
        ref={props.forwardRef}
        href={props.href}
        className={props.className}
        style={props.style}
      >
        {props.children}
      </a>
    )
  }else{
    return (
      <div
        ref={props.forwardRef}
        className={props.className}
        style={props.style}
      >
        {props.children}
      </div>
    )
  }
}

const isUrl = string => {
  try { return Boolean(new URL(string)); }
  catch(e){ return false; }
}

const Campaigns = ({campaignDefs, onClickCampaign, ...props}) => {
  const {t} = translation()
  var color1 = "#3B7779"
  var color2 = "#FFB84F"

  const history = useHistory();
  const campaignBlock = useRef(null);
  const [campaignBlockHeight, setCampaignBlockHeight] = useState(null)

  useEffect(() => {
    // setCampaignBlockHeight(`${campaignBlock.current.clientWidth * 0.6}px`)
    setCampaignBlockHeight(78)
  }, [campaignBlock]);

  return (
      <>
      <CampaignsContainer 
        id="merchant-campaigns" 
        color1={color1} color2={color2}
      >
        {
          props.auth? (
            <CampaignsProfile 
              className='w-100 shadow-sm'
              color1={color1} color2={color2}
            >
              <div className='profile-wrap'>
                <div className='profile-icon' style={{
                  backgroundImage: `url("${
                    !isEmpty(props.userInfo)? 
                      (props.userInfo.profilePicURL? (
                        isUrl(props.userInfo.profilePicURL)? props.userInfo.profilePicURL: (
                          client.io.io.uri + props.userInfo.profilePicURL
                        )
                      ): UserLogo)
                    : UserLogo 
                  }")`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  position: 'relative'
                }}>
                  <img
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      width: 18,
                      cursor: 'pointer'
                    }} 
                    src={EditIcon} 
                    onClick={() => history.push(`/${props.merchant.username}/profile`)}
                    alt="" />
                </div>
                <div className='profile-info'>
                  <h3 className='text-capitalize'>
                    {t('Home Welcome')}, {" "}
                    {!isEmpty(props.userInfo)?
                      props.userInfo.lastName || props.userInfo.firstName?
                      (props.userInfo.firstName || "") + " " + (props.userInfo.lastName || "")
                      :
                      props.userInfo.email.split("@")[0]
                    :""}
                  </h3>
                  <p>{t('Home LastLogin')}: {
                    props.userInfo.lastLogin? 
                    moment(props.userInfo.lastLogin).format('DD/MM/YYYY - HH:mm')
                    : moment(new Date()).format('DD/MM/YYYY - HH:mm')
                    }
                  </p>
                  <Button 
                    className='logout'
                    style={{backgroundColor: color1}}
                    onClick={() => {
                      props.signout()
                    }}
                    ref={(node) => {
                      if (node) {
                        node.style.setProperty("font-size", "14px", "important");
                      }
                    }}
                  >
                    {t('Home Logout')}
                  </Button>
                </div>
              </div>
            </CampaignsProfile>
          ): null
        }
        {
          campaignDefs &&
          campaignDefs.map((campaign, index) =>
            <CampaignDefsComponent
              key={index} 
              forwardRef={index === 0 ? campaignBlock : null}
              href={campaign.route}
              campaign={campaign}
              className={classNames('campaign', {
                "wallet": index === 5,
                "unavailable": !campaign.available
              })}
              style={campaignBlockHeight ? {height: campaignBlockHeight} : {}}
            >
              <>
                <div style={{width: '25%'}} className="text-center">
                  <img src={campaign.icon} style={{width: 30}} alt="" />
                </div>
                <div className='border-right mr-2 ml-1 h-100'></div>
                <div style={{width: '70%'}}>
                  {/* {index === 4 && <span>{campaign.points}</span>} */}
                  {/* {index === 5 && <span>{campaign.currency + campaign.cash}</span>} */}
                  {" "}
                  <span>{t(`Home ${campaign.name}`)}</span>
                </div>
                {
                  // campaign.notification?
                  index <= 3?
                  <span className='badge' style={{backgroundColor: color2}}>{campaign.notification}</span>
                  : null
                }
              </>
            </CampaignDefsComponent>
          )
        }
      </CampaignsContainer>
      {
        !props.auth? (
          <div className='d-flex justify-content-center mt-2 mb-5' 
            style={{
              padding: '0px 16px 0px 16px'
            }}>
            <button className='btn shadow-sm' style={{
              width: '48%',
              height: 40,
              background: color1,
              boxShadow: '0px 4px 14px rgba(217, 217, 217, 0.25)',
              borderRadius: 7,
              marginRight: 10
            }}
            onClick={() => {
              props.setIsLogin(true) 
              props.toggleLoginModal()
            }}
            >
              <span style={{
                fontWeight: 700,
                fontSize: 15,
                color: '#FFFFFF',
                textTransform: 'uppercase'
              }}>{t('Home Login')}</span>
            </button>
            <button className='btn shadow-sm' style={{
              width: '48%',
              height: 40,
              background: color1,
              boxShadow: '0px 4px 14px rgba(217, 217, 217, 0.25)',
              borderRadius: 7
            }}
            onClick={() => {
              props.setIsLogin(false) 
              props.toggleLoginModal()
            }}
            >
              <span style={{
                fontWeight: 700,
                fontSize: 15,
                color: '#FFFFFF',
                textTransform: 'uppercase'
              }}>{t('Home SignUp')}</span>
            </button>
          </div>
        ): null
      }
    </>
  );
}

export default Campaigns;
